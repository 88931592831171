/* eslint-disable complexity */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { findIndex, isEmpty, some, get } from 'lodash';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import { InView } from 'react-intersection-observer';
import classNames from 'classnames';
import { parse } from 'query-string';

import { fetchRecentlyOrderedItems } from 'api/menu';

import useFetchMenu from 'hooks/useFetchMenu';
import useIsMobile from 'hooks/useIsMobile';
import usePrevious from 'hooks/usePrevious';
import usePlatform from 'hooks/usePlatform';
import useSelectItem from 'hooks/useSelectItem';

import { MenuContext } from 'context/MenuContext';
import { ModalContext } from 'context/ModalContext';
import { UserContext } from 'context/UserContext';

import breakpoints from 'helpers/breakpoints';
import {
  ABOVE_ORDER_DELIVERY_MAX,
  ABOVE_ORDER_PICKUP_MAX,
  BELOW_ORDER_DELIVERY_MIN,
  BELOW_ORDER_PICKUP_MIN,
} from 'helpers/api';
import {
  ANALYTICS_EVENT_NAME,
  logAnalyticsEvent,
  logException,
} from 'helpers/loggers';
import { MODAL_TYPE } from 'helpers/modals';
import {
  calculateNumItems,
  FULFILLMENT_METHODS,
  getAppliedPromo,
} from 'helpers/order';
import { SELECT_ITEM_OPERATIONS } from 'helpers/constants';
import { getCnChannel } from 'helpers/customer';
import { isWithin30Days } from 'helpers/date';
import { formatDiscount } from 'helpers/format';
import { buildRoute, ROUTES } from 'helpers/routes';
import { onHeightChangedHandler } from 'helpers/yelp';

import {
  getFulfillmentTime,
  getErrorsForOrder,
  getIsOrderValidating,
  getOrderItemData,
  getOrderData,
  setTableNameRequest,
  updateOrderRequest,
} from 'modules/order';
import {
  getIsTableRequired,
  getNextAvailableTime,
  getRestaurantDetails,
  getRestaurantMembershipEnabled,
} from 'modules/restaurant';

import MembershipBanner from 'components/MembershipBanner';
import MenuCategoryNav from 'components/MenuCategoryNav';
import MenuItemCarousel from 'components/MenuItemCarousel';
import MenuItems from 'components/MenuItems';
import Order from 'components/Order';
import BagErrors from 'components/BagErrors';
import Disclaimer from 'components/Disclaimer';
import MenuBanner from 'components/MenuBanner';
import FulfillmentPicker from 'components/FulfillmentPicker';
import PromoBanner from 'components/PromoBanner';
import Button from 'primitives/Button';
import Grid from 'primitives/Grid';
import Toast from 'primitives/Toast';
import LoadingSpinner from 'primitives/LoadingSpinner';

import { ReactComponent as IconBag } from 'images/icon-bag.svg';
import { ReactComponent as IconPoweredBy } from 'images/icon-poweredby.svg';
import { ReactComponent as IconBadgeCheck } from 'images/icon-badge-check.svg';

import MenuPreviewBanner from './MenuPreviewBanner';

import styles from './styles.module.scss';

let lasPos = window.pageYOffset;
let badgeTimer;
const BADGE_TIMEOUT = 150;
const DEFAULT_CATEGORY = 'Categories';
export const getFilteredCategories = (categories, searchQuery) =>
  categories.reduce((categoriesArray, category) => {
    const { items } = category;
    let filteredItems;

    // if category name matches search, add all itmes
    if (category.name.toLowerCase().includes(searchQuery.toLowerCase())) {
      filteredItems = category.items;
    } else {
      // else, just add items that match search
      filteredItems = items.filter(
        (item) =>
          item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          (item.description &&
            item.description.toLowerCase().includes(searchQuery.toLowerCase()))
      );
    }

    if (filteredItems.length) {
      categoriesArray.push({
        ...category,
        items: filteredItems,
      });
    }
    return categoriesArray;
  }, []);

export function filterOutCurbsideCategory(categories, hasCurbside) {
  const updatedCategories = categories.filter((category) => {
    const hasCurbsideCategory = category.name === 'Curbside Pickup';

    const hasCurbsideItem = category.items.some(
      (item) => item.name === 'Curbside (Add to Cart for Curbside Pickup)'
    );

    return !(hasCurbsideCategory && hasCurbsideItem);
  });

  // to check if curbside fulfillment is available and filter out curbside menu item
  return hasCurbside ? updatedCategories : categories;
}

function Menu(props) {
  const { disclaimer, isMenuPreview, stickyHeader } = props;

  const { openModal, closeModal } = useContext(ModalContext);
  const { menu, isMenuLoading, menuCategoriesWithItem, menuItems } =
    useContext(MenuContext);
  const categories = menuCategoriesWithItem;
  const { isLoggedIn, user, membership = {} } = useContext(UserContext);
  const { userMembershipPlan, hasActiveMembership, expiredMemberships } =
    membership;

  const history = useHistory();
  const location = useLocation();
  const { hqId, restaurantId } = useParams();
  const dispatch = useDispatch();

  const { isChowNowPlatform, isDirectPlatform, isYelpPlatform } = usePlatform();

  const [activeCategory, setActiveCategory] = useState(DEFAULT_CATEGORY);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [isBagVisible, setIsBagVisible] = useState(false);
  const [prevCategory, setPrevCategory] = useState(DEFAULT_CATEGORY);
  const [searchQuery, setSearchQuery] = useState('');
  const [isFulfillmentIntersecting, setIsFulfillmentIntersecting] =
    useState(false);
  const [isDisclaimerIntersecting, setIsDisclaimerIntersecting] =
    useState(false);
  const [isBagSticky, setIsBagSticky] = useState(false);
  const [numItemsChanged, setNumItemsChanged] = useState(false);
  const [isLoadingRecentlyOrderedItems, setIsLoadingRecentlyOrderedItems] =
    useState(true);
  const [recentlyOrderedItems, setRecentlyOrderedItems] = useState();
  const [showPromoToast, setShowPromoToast] = useState(false);

  const errors = useSelector(getErrorsForOrder);
  const isMembershipEnabled = useSelector(getRestaurantMembershipEnabled);
  const isTableRequired = useSelector(getIsTableRequired);
  const isValidating = useSelector(getIsOrderValidating);
  const nextFulfillmentOptions = useSelector(getNextAvailableTime);
  const order = useSelector(getOrderData);
  const orderItems = useSelector(getOrderItemData);
  const restaurant = useSelector(getRestaurantDetails);
  const when = useSelector(getFulfillmentTime);
  const queryParams = parse(location.search);
  const cnChannel = getCnChannel();
  const prevMenu = usePrevious(menu);
  const prevMenuItems = usePrevious(menuItems);
  const prevWhen = usePrevious(when);
  const prevChannel = usePrevious(cnChannel);
  const prevQueryParams = usePrevious(queryParams);
  const numItems = calculateNumItems(orderItems);
  const prevNumItems = usePrevious(numItems);

  const onSelectItem = useSelectItem();

  useFetchMenu({
    restaurantId,
    when,
    shouldRefetch: prevWhen !== when,
  });

  const { fulfillmentMethod } = order;
  const hasCurbside = !!restaurant.fulfillment.curbside;

  const isDineIn = fulfillmentMethod === FULFILLMENT_METHODS.dineIn;

  const hasBagErrors =
    some(errors, { code: ABOVE_ORDER_PICKUP_MAX }) ||
    some(errors, { code: BELOW_ORDER_PICKUP_MIN }) ||
    some(errors, { code: ABOVE_ORDER_DELIVERY_MAX }) ||
    some(errors, { code: BELOW_ORDER_DELIVERY_MIN });
  const lastExpiredMembership =
    expiredMemberships && expiredMemberships[expiredMemberships.length - 1];
  const isMembershipExpiredWithin30Days = isWithin30Days(
    get(lastExpiredMembership, 'expires_at')
  );
  const showMembershipBanner =
    !hasActiveMembership &&
    isMembershipEnabled &&
    !isDineIn &&
    isDirectPlatform;

  const isMobile = useIsMobile();
  const isNotASearch = searchQuery === '';

  const CATEGORY_OBSERVER_OFFSET = isMobile ? 224 : 208;

  const appliedPromo = getAppliedPromo(order.discounts);
  const hasAutoApplyPromo = !isEmpty(restaurant?.available_discounts);
  const canShowPromoBanner = hasAutoApplyPromo && isChowNowPlatform;

  const userHasRedeemedPromos = !isEmpty(restaurant.redeemed_discounts);

  async function getRecentlyOrderedItemsObject() {
    setIsLoadingRecentlyOrderedItems(true);
    try {
      const recentlyOrderedItemsResponse = await fetchRecentlyOrderedItems({
        restaurantId,
        when,
        channel: cnChannel,
      });
      setRecentlyOrderedItems(recentlyOrderedItemsResponse);
    } catch (error) {
      setRecentlyOrderedItems(null);
      logException({ message: `Recently Ordered Items Error: ${error}` });
    }

    setIsLoadingRecentlyOrderedItems(false);
  }

  useEffect(() => {
    if (user?.id) {
      setRecentlyOrderedItems(getRecentlyOrderedItemsObject());
    } else {
      setRecentlyOrderedItems(null);
      setIsLoadingRecentlyOrderedItems(false);
    }
  }, [user]);

  useEffect(() => {
    // if menu changes, update recently ordered items
    if (!isEmpty(prevMenu) && user?.id) {
      setRecentlyOrderedItems(getRecentlyOrderedItemsObject());
    } else {
      setRecentlyOrderedItems(null);
      setIsLoadingRecentlyOrderedItems(false);
    }
  }, [menu]);

  useEffect(() => {
    if (userHasRedeemedPromos) {
      setShowPromoToast(true);
    }

    setTimeout(() => {
      setShowPromoToast(false);
    }, 5000);
  }, [userHasRedeemedPromos]);

  function closeBag() {
    closeModal();
    setIsBagVisible(false);
  }

  function handleResize() {
    if (isBagVisible && window.innerWidth > breakpoints.smMin) {
      closeBag();
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isBagVisible]);

  // Frequently Ordered menu data will be attached to user and should only show
  // category if available.
  const canShowOrderAgainCategory =
    isLoggedIn &&
    !!recentlyOrderedItems?.length &&
    isNotASearch && // do not show recently ordered items when there is a search query
    !isYelpPlatform;

  function handleCheckout() {
    history.push(buildRoute({ hqId, restaurantId, route: ROUTES.checkout }));
  }

  function logMParticleEvent() {
    logAnalyticsEvent({
      eventName: ANALYTICS_EVENT_NAME.viewMobileWebOrderSummary,
      attributes: {
        menu_id: menu.id,
        restaurant_location_id: restaurantId,
        restaurant_brand_id: hqId,
        order_subtotal: order.item_total,
        total_item_quantity: numItems,
      },
    });
  }

  function openBag() {
    logMParticleEvent();
    setIsBagVisible(true);
    openModal(MODAL_TYPE.bag, {
      handleCheckout,
      showMembershipBanner,
      showPromoBanner: canShowPromoBanner && !isLoggedIn,
      hasActiveMembership,
      userMembershipPlan,
      isRenewExpirationBanner: isMembershipExpiredWithin30Days,
      expiredMembership: lastExpiredMembership,
    });
  }

  useEffect(() => {
    if (isTableRequired && queryParams.table) {
      const { table } = queryParams;
      /**
       * Don't want to do validation on initial table name set as this gets called before menu is
       * successfully loaded. Another validate will happen once menu loads
       */
      dispatch({
        type: setTableNameRequest.TYPE,
        payload: table,
        meta: { disableValidation: true },
      });
      // Clear table query param after setting in redux to avoid conflicts with editing table at Checkout
      history.push(location.pathname);
    }

    return () => {
      if (isBagVisible) {
        closeBag();
      }
    };
  }, []);

  useEffect(() => clearTimeout(badgeTimer), []);

  useEffect(() => {
    if (prevNumItems > 0 && prevNumItems !== numItems) {
      setNumItemsChanged(true);

      badgeTimer = setTimeout(() => {
        setNumItemsChanged(false);
      }, BADGE_TIMEOUT);
    }
  }, [numItems]);

  useEffect(() => {
    if (prevMenu && prevMenu.id !== menu.id) {
      // on load of menu, if we have cn_channel, update order meta
      if (prevChannel !== cnChannel) {
        dispatch({
          type: updateOrderRequest.TYPE,
        });
      }
    }
  }, [menu.id]);

  useEffect(() => {
    if (prevMenuItems?.length === 0 && menuItems.length) {
      // On load of menu, if menu item query param is present, attempt to open item modal
      if (queryParams && queryParams.menu_item_id) {
        onSelectItem({
          itemId: queryParams.menu_item_id,
          operation: SELECT_ITEM_OPERATIONS.add,
        });
      }
    }
  }, [menuItems]);

  useEffect(() => {
    // reset bag visible state to false when opening menu item modal from bag modal
    if (queryParams && queryParams.order_item_id && isBagVisible) {
      setIsBagVisible(false);
    }

    // if these conditions are met, we want to reopen bag modal once finishing in the menu item modal
    const isComingFromMenuItemModal =
      isEmpty(queryParams) &&
      get(prevQueryParams, 'order_item_id') &&
      !isBagVisible &&
      isMobile;
    if (isComingFromMenuItemModal) {
      openBag();
    }
  }, [queryParams]);

  // Filter menu items and categories by search query
  useEffect(() => {
    // wait until we know if we can show popular items or not before loading categories otherwise
    // we have flash of no popular items category before category is shown
    if (!categories) return;

    const filteredCategoriesNoCurbside = filterOutCurbsideCategory(
      categories,
      hasCurbside
    );
    setFilteredCategories(
      getFilteredCategories(filteredCategoriesNoCurbside, searchQuery)
    );
  }, [searchQuery, categories]);

  // For Yelp, each time the categories are filtered, there is a potential
  // for the Menu height to change.  We need to notify Yelp when this happens.
  useEffect(() => {
    if (isYelpPlatform) {
      onHeightChangedHandler();
    }
  }, [filteredCategories]);

  function handleIntersection(inView, entry, category) {
    const boundingClientRect = entry ? entry.boundingClientRect : null;

    if (!boundingClientRect) return;

    const isAboveHeader = boundingClientRect.y < CATEGORY_OBSERVER_OFFSET;
    const categoryIndex =
      category &&
      findIndex(filteredCategories, {
        name: category.name,
      });
    /*
    We can determine if the element is at the top of the window if its
    y-intersection is less than scroll distance
    */
    const rootScrollableElement = document.querySelector('.theme-yelp');
    const scrollDistance = isYelpPlatform
      ? Math.abs(lasPos - rootScrollableElement.scrollTop)
      : Math.abs(lasPos - window.pageYOffset);
    const isLessThanScroll =
      boundingClientRect.y < scrollDistance + CATEGORY_OBSERVER_OFFSET;

    lasPos = isYelpPlatform
      ? rootScrollableElement.scrollTop
      : window.pageYOffset;

    if (!inView && isAboveHeader) {
      if (category) setActiveCategory(category.name);
    } else if (
      inView &&
      filteredCategories[categoryIndex - 1] &&
      isLessThanScroll
    ) {
      // when scrolling in reverse we want to grab the category above
      setActiveCategory(filteredCategories[categoryIndex - 1].name);
    } else if (inView && isLessThanScroll) {
      setActiveCategory(DEFAULT_CATEGORY);
    }

    if (
      prevCategory !== activeCategory &&
      (window.pageYOffset > 0 || isYelpPlatform)
    ) {
      setPrevCategory(activeCategory);
    }
  }

  function handleStickyBagState(inView) {
    setIsBagSticky(!inView);
  }

  function handlePromoToastClick() {
    const allRedeemedPromoDesc = restaurant.redeemed_discounts.map(
      (discount) => `"${discount.short_description}"`
    );

    openModal(MODAL_TYPE.dialog, {
      title: `Some promos have already been redeemed`,
      message: `Unfortunately, you've already redeemed the ${
        allRedeemedPromoDesc.length > 1 ? 'promos' : 'promo'
      } ${allRedeemedPromoDesc.join(', ')} from ${restaurant.name}.`,
      confirmLabel: `OK`,
      isAlert: true,
    });
  }

  function toggleDisclaimerModal() {
    openModal(MODAL_TYPE.dialog, {
      title: 'Menu Disclaimer',
      message: disclaimer,
      isAlert: true,
      isCentered: false,
    });
  }

  if (isMenuLoading || isLoadingRecentlyOrderedItems) {
    return <LoadingSpinner overlay />;
  }

  const desktopCheckoutButtonLabel = isChowNowPlatform
    ? 'Checkout'
    : 'Review Order';
  const mobileCheckoutButtonLabel = isChowNowPlatform
    ? `${numItems} ${numItems > 1 ? 'Items' : 'Item'} in Order`
    : 'View Cart';

  if (filteredCategories) {
    const root = isYelpPlatform ? document.querySelector('.theme-yelp') : null;

    return (
      <>
        <div>
          <InView
            onChange={(inView) => setIsDisclaimerIntersecting(inView)}
            rootMargin="2px 0px 0px 0px"
          >
            {disclaimer && (
              <Disclaimer
                disclaimer={disclaimer}
                isDineIn={isDineIn}
                isMenu
                handleClick={toggleDisclaimerModal}
              />
            )}
          </InView>

          <MenuBanner restaurant={restaurant} />
          <div
            className={classNames(styles.promoToast, {
              [styles.withCheckoutButton]: !!numItems && isMobile,
              [styles.isVisible]: showPromoToast,
            })}
          >
            <Toast
              title="Some promos have already been redeemed"
              linkText="View Details"
              handleClick={handlePromoToastClick}
              handleOnClickClose={() => setShowPromoToast(false)}
            />
          </div>
          <Grid
            container
            className={classNames(styles.menuWrapper, {
              [styles.sticky]: stickyHeader,
            })}
          >
            <Grid className={styles.itemListWrapper} sm={12} md={7} lg={8}>
              <MediaQuery minWidth={breakpoints.smMin}>
                <div className={styles.bottomBarLeftWrapper}>
                  <MenuCategoryNav
                    categories={filteredCategories}
                    stickyHeader={stickyHeader}
                    activeCategory={activeCategory}
                    prevCategory={prevCategory}
                    searchQuery={searchQuery}
                    onSearchQuery={setSearchQuery}
                  />
                </div>
              </MediaQuery>
              <MediaQuery maxWidth={breakpoints.xsMax}>
                {!isDineIn && !isMenuPreview && (
                  <InView
                    onChange={(inView) => setIsFulfillmentIntersecting(inView)}
                    rootMargin="2px 0px 0px 0px"
                  >
                    <FulfillmentPicker
                      isClosed={isEmpty(nextFulfillmentOptions)}
                    />
                  </InView>
                )}
                {isMenuPreview && (
                  <InView
                    onChange={(inView) => setIsFulfillmentIntersecting(inView)}
                    rootMargin="2px 0px 0px 0px"
                  >
                    <MenuPreviewBanner />
                  </InView>
                )}
                {(filteredCategories.length || searchQuery) && (
                  <MenuCategoryNav
                    categories={filteredCategories}
                    stickyHeader={
                      (!isDineIn &&
                        !isMenuPreview &&
                        !isFulfillmentIntersecting) ||
                      (isDineIn && !isDisclaimerIntersecting) ||
                      (isMenuPreview && !isFulfillmentIntersecting)
                    }
                    activeCategory={activeCategory}
                    prevCategory={prevCategory}
                    searchQuery={searchQuery}
                    onSearchQuery={setSearchQuery}
                  />
                )}
              </MediaQuery>

              <div
                className={classNames(styles.menu, {
                  [styles.noFooter]: !isChowNowPlatform,
                })}
              >
                <div
                  className={classNames(styles.recentlyOrderedWrapper, {
                    [styles.carouselLoaded]: canShowOrderAgainCategory,
                  })}
                >
                  {canShowOrderAgainCategory && (
                    <>
                      <div className={styles.categoryNameWrapper}>
                        <span
                          className={classNames(
                            styles.categoryName,
                            styles.categoryNameCarousel
                          )}
                        >
                          Order it Again
                        </span>
                      </div>
                      <MenuItemCarousel items={recentlyOrderedItems} />
                    </>
                  )}
                </div>
                {canShowPromoBanner && (
                  <div className={styles.promoBanner}>
                    <PromoBanner promos={restaurant.available_discounts} />
                  </div>
                )}
                {filteredCategories.map((category, index) => (
                  <div
                    key={category.id}
                    id={`category_${index}`}
                    className={styles.categorySection}
                  >
                    <InView
                      root={root}
                      rootMargin={`-${CATEGORY_OBSERVER_OFFSET}px 0px 0px 0px`}
                      onChange={(inView, entry) =>
                        handleIntersection(inView, entry, category)
                      }
                    >
                      <div className={styles.categoryNameWrapper}>
                        <span className={styles.categoryName}>
                          {category.name}
                        </span>
                      </div>
                    </InView>
                    <div id="menuItems">
                      <MenuItems items={category.items} />
                    </div>
                  </div>
                ))}
                {!filteredCategories.length && searchQuery && (
                  <div className={styles.noResults}>
                    <h4>
                      Sorry, no menu items found for &ldquo;{searchQuery}&rdquo;
                    </h4>
                    <p className={styles.tryAgain}>
                      Please try searching for something else or make sure the
                      spelling is correct.
                    </p>
                  </div>
                )}
              </div>
              <div
                className={classNames(styles.bagSpace, {
                  [styles.fullBag]: !!numItems,
                })}
              >
                <InView onChange={(inView) => handleStickyBagState(inView)} />
              </div>
            </Grid>
            <MediaQuery minWidth={breakpoints.smMin}>
              <Grid className={styles.orderTotal} sm={0} md={5} lg={4}>
                <div className={styles.fixedBag}>
                  {!isDineIn && !isMenuPreview && (
                    <FulfillmentPicker
                      isClosed={isEmpty(nextFulfillmentOptions)}
                    />
                  )}
                  {isMenuPreview ? (
                    <MenuPreviewBanner />
                  ) : (
                    <Order
                      hasDisclaimer={!!disclaimer}
                      isOnMenu
                      isEmptyBag={!numItems}
                      showOrderToggle
                    />
                  )}
                  {!!numItems && !isMenuPreview && (
                    <div className={styles.btnWrapper}>
                      <Button
                        onClick={handleCheckout}
                        isFullWidth
                        dataTestId="CheckoutButton"
                        disabled={hasBagErrors}
                      >
                        {desktopCheckoutButtonLabel}
                      </Button>
                      {!!orderItems.length && hasBagErrors && !isValidating && (
                        <div className={styles.bagErrors}>
                          <BagErrors />
                        </div>
                      )}
                      {!!appliedPromo?.length && (
                        <div className={styles.promoApplied}>
                          <IconBadgeCheck className={styles.badgeCheck} />
                          {formatDiscount(appliedPromo[0].amount)} promo will be
                          applied at checkout
                        </div>
                      )}
                    </div>
                  )}
                  {!isChowNowPlatform && (
                    <div className={styles.poweredByIcon}>
                      <IconPoweredBy />
                    </div>
                  )}
                  {showMembershipBanner && (
                    <MembershipBanner
                      restaurantId={restaurantId}
                      hqId={hqId}
                      isMinimized={!!numItems}
                      isRenewBanner={isMembershipExpiredWithin30Days}
                      expiredMembership={lastExpiredMembership}
                    />
                  )}
                </div>
              </Grid>
            </MediaQuery>
          </Grid>
        </div>
        <MediaQuery maxWidth={breakpoints.xsMax}>
          <div>
            {!!numItems && (
              <div
                className={classNames(styles.fixedBtn, {
                  [styles.btnBagWrap]: isBagSticky,
                  [styles.slideDown]: numItemsChanged,
                  [styles.slideUp]: !numItemsChanged,
                })}
              >
                {!!appliedPromo?.length && (
                  <div className={styles.promoApplied}>
                    <IconBadgeCheck className={styles.badgeCheck} />
                    {formatDiscount(appliedPromo[0].amount)} promo will be
                    applied at checkout
                  </div>
                )}
                <div className={styles.btnBagContainer}>
                  <Button
                    className={styles.btnBag}
                    icon={IconBag}
                    onClick={openBag}
                    isFullWidth
                    dataTestId="Bag"
                  >
                    {mobileCheckoutButtonLabel}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </MediaQuery>
      </>
    );
  }

  return <div>CANNOT FIND MENU</div>;
}

Menu.propTypes = {
  disclaimer: PropTypes.string,
  isMenuPreview: PropTypes.bool,
  stickyHeader: PropTypes.bool,
};

Menu.defaultProps = {
  disclaimer: undefined,
  isMenuPreview: false,
  stickyHeader: false,
};

export default Menu;
