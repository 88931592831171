/* eslint-disable complexity, react/jsx-no-useless-fragment, import/extensions */
import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { formatMoney } from '@chownow/cn-web-utils/format';
import { isOnPage } from '@chownow/cn-web-utils/url';
import {
  ORDER_STATUSES,
  calculateTotalCredits,
  FULFILLMENT_METHODS,
} from 'helpers/order';
import { orderTotalsType } from 'helpers/prop-types';
import { toTitleCase } from 'helpers/format';
import useShowRestaurantTipping from 'hooks/featureFlags/useShowRestaurantTipping';
import { getSelectedOrder } from 'modules/order';

import InfoTooltip from 'components/InfoTooltip';
import OrderTotalsTip from './OrderTotalsTip';

import styles from './styles.module.scss';

function OrderTotals(props) {
  const { isReadOnly, order, showLineItems } = props;

  const {
    credits,
    delivery_fee: deliveryFee,
    discounts,
    fulfill_method: fulfillMethod, // Order API returning snake case
    fulfillmentMethod, // Cart returning as camelCase
    // 'order/{#id}' API returns managed delivery object but 'validate' returns 'managed_delivery_id' only
    managed_delivery: managedDelivery,
    managed_delivery_id: managedDeliveryId,
    marketplace_diner_fee: marketplaceDinerFee,
    misc_fee: miscFee,
    sales_tax: salesTax,
    item_total: itemTotal,
    status,
    total_due: totalDue,
  } = order;
  const pastOrder = useSelector(getSelectedOrder);
  const isDelivery =
    (fulfillmentMethod || fulfillMethod) === FULFILLMENT_METHODS.delivery;
  const totalCredits = calculateTotalCredits(credits);
  const activeDiscount = discounts && discounts[0];
  const isPromo = activeDiscount && activeDiscount.promo_code;
  const discountTitle = isPromo ? 'Promo' : 'Discount';
  const isMembershipDiscount = activeDiscount && activeDiscount.is_membership;
  const isCanceled = status === ORDER_STATUSES.canceled;

  const isCheckout = isOnPage('checkout');
  const isDualTippingAllowed = useShowRestaurantTipping();

  // For pre-order finalization, we use the "tips" object from POST /validate
  // but for post-order we use the "tips" object from GET /order/:id
  const tipsOrder = isReadOnly ? pastOrder : order;
  const baseRestaurantTip = tipsOrder?.tips?.restaurant_tip;
  const baseManagedDeliveryTip = tipsOrder?.tips?.managed_delivery_tip;

  const restaurantTip = baseRestaurantTip?.restaurant_tip_amount;
  const managedDeliveryTip =
    baseManagedDeliveryTip?.managed_delivery_tip_amount;
  const totalTip = (restaurantTip || 0) + (managedDeliveryTip || 0);

  const simpleTip = restaurantTip || managedDeliveryTip;

  function isTipShown(tipValue) {
    return Boolean(tipValue && tipValue > 0);
  }

  function isSimpleTipShown() {
    // on Order History we never show simple tip
    if (isReadOnly) {
      return false;
    }

    // on Checkout:
    // if FF is on: we never show simple tip
    if (isDualTippingAllowed) {
      return false;
    }

    // if FF is off: we show the simple tip if we can show it
    return isTipShown(simpleTip);
  }

  function isTotalTipShown() {
    // on Order history we always show Total Tip if we can show it
    if (isReadOnly) {
      return isTipShown(totalTip);
    }

    // on Checkout:
    // if FF is on: show the total tip if we can show it
    if (isDualTippingAllowed) {
      return isTipShown(totalTip);
    }

    // if FF is off: we never show total tip
    return false;
  }

  function getTotalAmount(total) {
    if (isCanceled) {
      return '$0.00';
    }
    return formatMoney(total);
  }

  return (
    <>
      {showLineItems ? (
        <ul className={styles.wrapper}>
          <li className={styles.item}>
            <span className={styles.label}>Subtotal</span>
            <span className={styles.itemValue} data-testid="Subtotal">
              {getTotalAmount(itemTotal)}
            </span>
          </li>
          {!!salesTax && (
            <li className={styles.item}>
              <span className={styles.label}>Taxes</span>
              <span className={styles.itemValue} data-testid="Taxes">
                {getTotalAmount(salesTax)}
              </span>
            </li>
          )}
          {!!miscFee && !!miscFee.amount && (
            <li className={styles.item}>
              <span className={styles.label} data-testid="FeeLabel">
                <InfoTooltip
                  id="misc-fee-description"
                  label={miscFee.label}
                  className={styles.actionableLink}
                  showIcon={false}
                >
                  <div
                    className={styles.toolTipTitle}
                    data-testid="MiscFeeLabel"
                  >
                    {miscFee.label}
                  </div>
                  <p className={styles.description}>
                    This restaurant adds this fee to cover costs and continue
                    serving your community. 100% goes to the restaurant.
                  </p>
                </InfoTooltip>
              </span>
              <span className={styles.itemValue} data-testid="Fee">
                {getTotalAmount(miscFee.amount)}
              </span>
            </li>
          )}
          {isDelivery && (
            <li className={styles.item}>
              <span className={styles.label}>
                <InfoTooltip
                  id="delivery-description"
                  label="Delivery"
                  showIcon={false}
                  className={styles.actionableLink}
                >
                  <div
                    data-testid="DeliveryLabel"
                    className={styles.toolTipTitle}
                  >
                    Delivery Fee
                  </div>
                  <p className={styles.description}>
                    {
                      managedDeliveryId || managedDelivery
                        ? 'ChowNow connects restaurants to delivery partners so they can serve more diners. This fee helps them cover costs.' // eslint-disable-line max-len
                        : 'This restaurant adds this fee to support their drivers and serve more diners. 100% goes to the restaurant.' // eslint-disable-line max-len
                    }
                  </p>
                </InfoTooltip>
              </span>
              <span className={styles.itemValue} data-testid="DeliveryFee">
                {deliveryFee ? getTotalAmount(deliveryFee) : '-'}
              </span>
            </li>
          )}
          {isTotalTipShown() && (
            <OrderTotalsTip
              getTotalAmount={getTotalAmount}
              isCanceled={isCanceled}
              isTipShown={isTipShown}
              managedDeliveryTip={managedDeliveryTip}
              restaurantTip={restaurantTip}
              totalTip={totalTip}
              styles={styles}
            />
          )}
          {!!marketplaceDinerFee && (
            <li className={styles.item}>
              <span className={styles.label}>
                <InfoTooltip
                  id="diner-fee-description"
                  label={marketplaceDinerFee.label}
                  showIcon={false}
                  className={styles.actionableLink}
                >
                  <div
                    data-testid="DinerFeeLabel"
                    className={styles.toolTipTitle}
                  >
                    {marketplaceDinerFee.label}
                  </div>
                  <p className={styles.description}>
                    {marketplaceDinerFee.description}
                  </p>
                </InfoTooltip>
              </span>
              <span className={styles.itemValue} data-testid="DinerFee">
                {getTotalAmount(marketplaceDinerFee.amount)}
              </span>
            </li>
          )}
          {isSimpleTipShown() && (
            <li className={styles.item}>
              <span className={styles.label}>Tip</span>
              <span className={styles.itemValue} data-testid="OrderTip">
                {getTotalAmount(simpleTip)}
              </span>
            </li>
          )}
          {!!activeDiscount && (
            <li className={styles.item}>
              <span className={styles.label} data-testid="DiscountLabel">
                {isMembershipDiscount ? (
                  <div className={styles.label}>{activeDiscount.name}</div>
                ) : (
                  <InfoTooltip
                    id="discount-description"
                    label={discountTitle}
                    showIcon={false}
                    className={styles.actionableLink}
                  >
                    <div className={styles.toolTipTitle}>
                      {/* on confirmation we don't have short desc or long desc so need to use name and desc instead */}
                      {toTitleCase(activeDiscount.short_description) ||
                        activeDiscount.name}
                    </div>
                    <p className={styles.description}>
                      {activeDiscount.long_description ||
                        activeDiscount.description}
                    </p>
                  </InfoTooltip>
                )}
              </span>
              <span className={styles.itemValue} data-testid="Discount">
                - {getTotalAmount(activeDiscount.amount)}
              </span>
            </li>
          )}
          {!!totalCredits && (
            <li className={styles.item}>
              <span className={styles.label}>
                {isCheckout ? credits[0].description : credits[0].name}
              </span>
              <span className={styles.itemValue} data-testid="Credit">
                - {getTotalAmount(totalCredits)}
              </span>
            </li>
          )}
          <div className={styles.item}>
            <span className={classNames(styles.label, styles.total)}>
              Total
            </span>
            <span
              className={classNames(styles.itemValue, styles.total)}
              data-testid="OrderTotal"
            >
              {getTotalAmount(totalDue)}
            </span>
          </div>
        </ul>
      ) : (
        <div className={styles.subtotal}>
          <span>Subtotal</span>
          <span data-testid="Subtotal">{getTotalAmount(itemTotal)}</span>
        </div>
      )}
    </>
  );
}

OrderTotals.propTypes = {
  isReadOnly: PropTypes.bool,
  order: orderTotalsType.isRequired,
  showLineItems: PropTypes.bool,
};

OrderTotals.defaultProps = {
  isReadOnly: false,
  showLineItems: false,
};

export default OrderTotals;
