/* eslint-disable complexity */
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { get } from 'lodash';
import { parse } from 'query-string';

import { logMessage } from 'helpers/loggers';
import {
  redirectToMarketplaceMenu,
  setIsDirectToMpRedirect,
} from 'helpers/configureRedirects';
import { AUTH_MODAL_TYPE, MODAL_TYPE } from 'helpers/modals';
import { buildRoute, ROUTES } from 'helpers/routes';
import { isDirectUrl } from 'helpers/url';

import { CompanyContext } from 'context/CompanyContext';
import { MenuContext } from 'context/MenuContext';
import { ModalContext } from 'context/ModalContext';
import { UserContext } from 'context/UserContext';

import usePrevious from 'hooks/usePrevious';
import useIsMobile from 'hooks/useIsMobile';

import { resetOrder } from 'modules/order';
import { resetRestaurant } from 'modules/restaurant';

import NotFound from 'containers/NotFound';
import Navbar from 'components/Navbar';
import GoogleMap from 'components/GoogleMap';
import UnsupportedBrowser from 'containers/UnsupportedBrowser';
import LoadingSpinner from 'primitives/LoadingSpinner';
import MapGrid from 'primitives/MapGrid';

import LocationsList from './LocationsList';
import LocationSearch from './LocationSearch';

import styles from './styles.module.scss';

function Locations() {
  const { company, handleFetchCompany, locations, isCompanyLoading } =
    useContext(CompanyContext);
  const { setMenu } = useContext(MenuContext);
  const { openModal } = useContext(ModalContext);
  const { user } = useContext(UserContext);

  const [lat, setLat] = useState(get(locations[0], 'address.latitude') || 0);
  const [lng, setLng] = useState(get(locations[0], 'address.longitude') || 0);

  const dispatch = useDispatch();
  const location = useLocation();
  const { hqId } = useParams();

  const isMobile = useIsMobile();
  const prevLocations = usePrevious(locations);
  // there's a split second on app load where we haven't started loading the company yet
  // so isLoading shows as false when we're not actually ready to show any UI
  const isLoading = isCompanyLoading || prevLocations === undefined;
  const history = useHistory();

  const queryParams = parse(location.search);

  useEffect(() => {
    setMenu({}); // reset menu
    dispatch({ type: resetRestaurant.TYPE });
    dispatch({ type: resetOrder.TYPE });

    handleFetchCompany(hqId);
  }, []);

  useEffect(() => {
    if (prevLocations !== locations) {
      setLat(get(locations[0], 'address.latitude') || 0);
      setLng(get(locations[0], 'address.longitude') || 0);
    }
  }, [locations]);

  useEffect(() => {
    if (queryParams.email && queryParams.verify && !user.id) {
      // on load, if we have forgot password queries, open auth modal to reset pw
      openModal(MODAL_TYPE.auth, {
        activeAuthModal: AUTH_MODAL_TYPE.resetPassword,
        email: queryParams.email,
        resetVerifyCode: queryParams.verify,
      });
      // remove queryParams;
      history.replace(location.pathname);
    }
  }, [queryParams]);

  function handleOnLocationHover(address) {
    setLat(address.latitude);
    setLng(address.longitude);
  }
  if (locations) {
    if (locations.length === 1) {
      const restaurantId = locations[0].id;
      const menuRoute = buildRoute({
        hqId,
        restaurantId,
        route: ROUTES.menu,
      });

      try {
        const isEmbedded = window.top !== window;
        // Don't need to check for OBN on locations page level
        const shouldUseMpDomain =
          isDirectUrl() &&
          Object.prototype.hasOwnProperty.call(
            locations[0],
            'is_direct_live'
          ) &&
          !locations[0].is_direct_live;

        if (shouldUseMpDomain) {
          setIsDirectToMpRedirect();

          if (isEmbedded) {
            return (
              <UnsupportedBrowser
                isUnableToLoadSite
                isEmbedded
                isMpRedirect
                hqId={hqId}
                restoId={restaurantId}
              />
            );
          }

          redirectToMarketplaceMenu({
            restoId: restaurantId,
            hqId,
            shouldReplaceUrl: true,
            source: 'single-location-redirect',
          });

          // Need to return null here to not get flash of 404 page
          return null;
        }
      } catch (e) {
        // If it fails to redirect, we want to still allow orders through the direct site
        // so failing silently but logging to Sentry
        logMessage(`Failed to redirect to MP on locations picker: ${e}`);
      }
      const redirect = `${menuRoute}${location.search}`;
      return history.replace(redirect);
    }

    if (locations.length > 1) {
      return (
        <>
          <Helmet>
            <title>{company.name} Locations</title>
          </Helmet>
          <Navbar restaurantName={company.name} isLocations />
          <MapGrid isContainer className={styles.locations}>
            {!isMobile && (
              <MapGrid isMap>
                <GoogleMap lat={lat} lng={lng} />
              </MapGrid>
            )}
            <MapGrid className={styles.locationsList}>
              <div>
                <LocationSearch />
                {isLoading ? (
                  <LoadingSpinner />
                ) : (
                  <LocationsList
                    onLocationHover={handleOnLocationHover}
                    locations={locations}
                  />
                )}
              </div>
            </MapGrid>
          </MapGrid>
        </>
      );
    }
  }

  return isLoading ? <LoadingSpinner /> : <NotFound source="locations" />;
}

export default Locations;
