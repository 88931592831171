/* eslint-disable complexity */
import { all, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { filter, get, includes, pick, some } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import {
  ATTRIBUTES as A,
  orderAttributes,
  restaurantAttributes,
} from 'helpers/analytics';
import {
  request,
  ADDRESS_OUTSIDE_DELIVERY_AREAS,
  BETTER_DEAL_APPLIED,
  CURBSIDE_CLOSED,
  CURBSIDE_INVALID_VEHICLE_COLOR,
  CURBSIDE_INVALID_VEHICLE_TYPE,
  CURBSIDE_INVALID_TABLET_VERSION,
  CURBSIDE_MISSING_VEHICLE,
  CURBSIDE_MISSING_VEHICLE_COLOR,
  CURBSIDE_MISSING_VEHICLE_TYPE,
  CURBSIDE_UNAVAILABLE,
  DINE_IN_TABLE_NOT_ALLOWED,
  DINE_IN_TABLE_REQUIRED,
  MENU_EXPIRED,
  MENU_ID_ERROR_FIELD,
  JSON_REQUIRED_FIELD_MISSING,
  ORDER_LEAD_TIME_EXPIRED,
  PICKUP_UNAVAILABLE,
  PREPTIME_CHANGED,
  PREPTIME_REMOVED,
  DELIVERY_UNAVAILABLE,
  INVALID_PROMO_CODE,
  ITEM_EXPIRED,
  MOD_EXPIRED,
  MODS_INVALID_FOR_ITEM,
  TIMESLOT_ORDER_THROTTLED,
  TIP_TOO_BIG,
  TIP_AND_TIP_PERCENTAGE_EXCLUSIVE,
  CITY_ERROR_FIELD,
  RESTAURANT_NOT_LIVE,
  STREET_ERROR_FIELD,
  UNAUTHORIZED,
  CVV_REQUIRED,
  genericDeclineMsg,
  genericDeclineTitle,
  getIs5xxError,
  reorderWarnings,
  ORDER_DETAILS_API_VERSION,
  ORDER_SUBMIT_API_VERSION,
  ORDER_VALIDATE_API_VERSION,
  REORDER_VALIDATE_API_VERSION,
  RESTAURANT_DOES_NOT_ALLOW_TIP,
  RESTAURANT_DOES_NOT_ALLOW_SINGLE_USE,
  INVALID_MISSING_PHONE_NUMBER,
  LENGTH_BELOW_MIN,
} from 'helpers/api';
import {
  createAnalyticsProduct,
  ANALYTICS_EVENT_NAME,
  ANALYTICS_EVENT_TYPES,
  logAnalyticsCommerceEvent,
  logException,
  logAnalyticsEvent,
} from 'helpers/loggers';

import { MODAL_TYPE } from 'helpers/modals';

import {
  ORDER_STATUSES,
  FULFILLMENT_METHODS,
  setSubmitOrderCount,
} from 'helpers/order';
import {
  MAX_ORDER_CLOSURE_DESCRIPTION,
  REORDER_CATEGORY_NAME,
} from 'helpers/constants';
import {
  getSelectedAddress,
  setSelectedAddress,
  getUserSessionId,
} from 'helpers/customer';
import { checkoutRedirect, getYelpData } from 'helpers/yelp';
import { getIsDirectToMpRedirect } from 'helpers/configureRedirects';
import {
  isOnPage,
  isYelpPlatform,
  isMarketplacePlatform,
} from '@chownow/cn-web-utils/url';

import {
  fetchOrderDetailsXHR,
  getFulfillmentMethod,
  getFulfillmentTime,
  getItemsInOrder,
  getOrderDataId,
} from 'modules/order';
import {
  getIsDeliveryOnly,
  getIsTippingEnabled,
  getRestaurantDetails,
  getNextAvailableTime,
  getPickupEta,
  fetchRestaurantXHR,
} from 'modules/restaurant';

import { callConfig } from 'index';

import {
  addItemToOrderRequest,
  addItemToOrderSuccess,
  setOrderData,
  validateReorderXHR,
  fetchOrderStatusXHR,
  initiateCheckout,
  removeOrderItemRequest,
  removeOrderItemSuccess,
  removeRejectedItems,
  resetPromoCodeRequest,
  resetPromoCodeSuccess,
  resetOrder,
  resetTableNameRequest,
  resetTableNameSuccess,
  resetTimer,
  resetTipsRequest,
  resetTipsSuccess,
  resetSubmitOrderRequest,
  resetSubmitOrderSuccess,
  resetWhen,
  setOrderCompleted,
  setPromoCodeRequest,
  setPromoCodeSuccess,
  setTableNameRequest,
  setTableNameSuccess,
  setManagedDeliveryTipRequest,
  setManagedDeliveryTipSuccess,
  setRestaurantTipRequest,
  setRestaurantTipSuccess,
  tipAdjustmentXHR,
  setVehicleRequest,
  setVehicleSuccess,
  setWhenRequest,
  setWhenSuccess,
  startOrderFailure,
  startOrderRequest,
  startOrderSuccess,
  submitOrderXHR,
  submitYelpOrderXHR,
  updateOrderFailure,
  updateOrderRequest,
  updateOrderSuccess,
  updateOrderThrottlingMeta,
  updateOrderItemRequest,
  updateOrderItemSuccess,
  triggerValidate,
  validateOrderXHR,
  yelpOpportunityXHR,
} from './actions';
import {
  buildPrepTimeOrderPayload,
  buildOrderPayload,
  buildReorderPayload,
  buildSubmitOrderPayload,
  getSelectedOrder,
  serializeOrder,
  getOrderMeta,
  getOrderData,
  getYelpOrderId,
} from './store';

const isYelp = isYelpPlatform();

export const MENU_EXPIRED_MODAL_COPY = {
  message: 'The menu in view is no longer available.',
  title: 'Menu Expired',
};

export function* handleValidateOrder({ type, meta }) {
  if (meta && meta.disableValidation) return;

  // don't call validate if logging in or out on confirmation or membership pages
  if (isOnPage('history') || isOnPage('membership')) return;

  const orderDataId = yield select(getOrderDataId);

  // Only validate estimated_fulfillment_time we received from restaurant endpoint against validate
  // endpoint on initiateCheckout action by adding estimated_fulfillment_time to order payload
  const prepTime = yield select(getPickupEta);
  const orderPayload =
    type === initiateCheckout.TYPE && prepTime
      ? yield select(buildPrepTimeOrderPayload)
      : yield select(buildOrderPayload);

  const isLoggingOut = meta?.isLoggingOut;
  const selectedAddress = yield select(getSelectedAddress);
  const isDeliveryOnly = yield select(getIsDeliveryOnly);
  const isOrderAddressValid =
    selectedAddress &&
    selectedAddress.street_address1 &&
    selectedAddress.street_address1 !== 'undefined' &&
    selectedAddress.city &&
    selectedAddress.state &&
    selectedAddress.zip &&
    selectedAddress.country;

  // don't call validate for initial load of delivery only restaurants
  // with missing address data and show AddressModal instead.
  // additionally, show modal when logging out on delivery only restauratns as when this gets called, we
  // still have access to address momentarily before logoutSuccess clears it
  if (
    orderPayload.fulfill_method === FULFILLMENT_METHODS.delivery &&
    (!isOrderAddressValid || (isLoggingOut && isDeliveryOnly))
  ) {
    // eslint-disable-next-line consistent-return
    return yield callConfig.call.ModalContext.openModal(
      MODAL_TYPE.fulfillmentDetails,
      {
        fulfillmentMethod: orderPayload.fulfill_method,
        noCloseOverlay: isDeliveryOnly,
        shouldDefaultToPickup: !isDeliveryOnly,
      }
    );
  }

  // menu id would be missing when menu call fails and someone tries to make a change on
  // their order (i.e. changing fulfillment)
  // but don't check for menu_id while on location picker
  const isOnLocationPicker = isOnPage('locations') && !isOnPage('locations/');

  if (!orderPayload.menu_id && !isOnLocationPicker) {
    // eslint-disable-next-line consistent-return
    return yield callConfig.call.ModalContext.openModal(MODAL_TYPE.dialog, {
      onConfirm: () => {
        window.location.reload();
      },
      noCloseOverlay: true,
      isAlert: true,
    });
  }

  yield put(validateOrderXHR.request());

  // Default endpoint + version
  let endpoint = 'order/validate';

  // If running the Yelp theme, retrieve opportunity token from session storage.
  // Modify endpoint + version and include the token in the HTTP request to Hermosa.
  if (isYelp) {
    const yelpData = getYelpData();

    endpoint = 'yelp/validate';
    orderPayload.opportunity_token = yelpData.opportunity_token;
  }

  if (get(orderPayload, 'restaurant_id')) {
    yield request({
      requestAction: validateOrderXHR,
      path: endpoint,
      params: orderPayload,
      method: 'POST',
      meta: { ...meta, orderDataId },
      data: serializeOrder,
      apiVersion: ORDER_VALIDATE_API_VERSION,
    });
  }
}

export function* handleValidateReorder() {
  const { id } = yield select(getSelectedOrder);

  yield request({
    requestAction: validateReorderXHR,
    path: `order/${id}/reorder`,
    method: 'POST',
    data: {},
    apiVersion: REORDER_VALIDATE_API_VERSION,
  });
}

export function* handleTipAjustment({ payload }) {
  const { id } = yield select(getSelectedOrder);

  yield request({
    requestAction: tipAdjustmentXHR,
    path: `order/${id}/adjust`,
    params: payload,
    method: 'PATCH',
  });
}

export function* handleTipAjustmentSuccess() {
  // close modal
  yield callConfig.call.ModalContext.closeModal();
}

export function* orderRequestHelper(action, payload, meta, customOrderDataId) {
  /* customOrderDataId getting sent from reorder validate to
  override temp orderDataId getting set on location switch */
  const orderDataId = yield select(getOrderDataId);
  yield put(
    action(payload, { ...meta, orderDataId: customOrderDataId || orderDataId })
  );
}

export function* handleValidateReorderSuccess({ payload }) {
  const reorderPayload = yield select(buildReorderPayload);
  const userId = getUserSessionId();
  const orderDataId = `${userId}_${reorderPayload.restaurantId}`;

  const { warnings } = payload.result;
  const isFulfillmentWarning = warnings.some(
    (warning) =>
      warning.code === reorderWarnings.CURBSIDE_UNAVAILABLE ||
      warning.code === reorderWarnings.DELIVERY_UNAVAILABLE
  );
  const isMenuWarning = warnings.find(
    (warning) => warning.code === reorderWarnings.MENU_ITEM_UNAVAILABLE
  );

  // if previous fulfillment not available, default back to pickup
  if (isFulfillmentWarning) {
    reorderPayload.fulfillmentMethod = FULFILLMENT_METHODS.pickup;
  }

  // if a menu item is not available, remove item from reorder payload
  if (isMenuWarning) {
    reorderPayload.items = reorderPayload.items.filter(
      (item) =>
        !isMenuWarning.value.some(
          (value) => item.id === value.id || item.sizeId === value.id
        )
    );
  }

  yield orderRequestHelper(setOrderData, reorderPayload, {}, orderDataId);
}

export function* handleInvalidOrder({ payload, meta, type }) {
  if (meta && meta.setErrors) {
    meta.setErrors(payload.errors);
  }

  const orderErrors = get(payload, 'errors', []);
  const fulfillMethod = yield select(getFulfillmentMethod);
  const restaurant = yield select(getRestaurantDetails);
  const orderMeta = yield select(getOrderMeta);
  const errorMessage = orderErrors.length && orderErrors[0].message;
  const errorCode = orderErrors.length && parseInt(orderErrors[0].code, 10);
  const is5xxError = getIs5xxError(errorCode);
  const isDeliveryOnly = yield select(getIsDeliveryOnly);

  if (type === submitOrderXHR.failure.TYPE) {
    const selectedCard = yield callConfig.call.UserContext.selectedCard;
    // only send atttribute when not on MP
    const isEmbedded = isMarketplacePlatform()
      ? undefined
      : window.top !== window;
    logAnalyticsEvent({
      eventName: ANALYTICS_EVENT_NAME.purchaseFailure,
      eventType: ANALYTICS_EVENT_TYPES.Transaction,
      attributes: {
        error_reason: errorMessage,
        payment_type: selectedCard?.type,
        is_embedded_site: isEmbedded,
        is_direct_to_marketplace_redirect: getIsDirectToMpRedirect(),
        restaurant_location_id: restaurant?.id,
      },
    });
    logException({
      name: `Purchase Failure: ${errorMessage}`,
      message: `Error Code: ${errorCode}`,
    });
  }

  if (
    some(orderErrors, { code: PICKUP_UNAVAILABLE }) ||
    some(orderErrors, { code: DELIVERY_UNAVAILABLE }) ||
    some(orderErrors, { code: CURBSIDE_CLOSED }) ||
    some(orderErrors, { code: CURBSIDE_UNAVAILABLE }) ||
    some(orderErrors, { code: CURBSIDE_INVALID_TABLET_VERSION }) ||
    some(orderErrors, { code: ORDER_LEAD_TIME_EXPIRED }) ||
    (some(orderErrors, { code: JSON_REQUIRED_FIELD_MISSING }) &&
      some(orderErrors, { field: MENU_ID_ERROR_FIELD }))
  ) {
    // Update error modals with latest details
    yield request({
      requestAction: fetchRestaurantXHR,
      path: `restaurant/${restaurant.id}`,
    });

    const updatedRestaurant = yield select(getRestaurantDetails);

    // CN-10963 need to reset order if temp closed by OrderBot
    if (
      updatedRestaurant.closures.length &&
      updatedRestaurant.closures.find(
        (closure) => closure.description === MAX_ORDER_CLOSURE_DESCRIPTION
      )
    ) {
      yield put(resetOrder());
      return;
    }

    const isAheadAvailable = get(
      updatedRestaurant.order_ahead,
      `is_${fulfillMethod}_ahead_available`
    );

    if (isAheadAvailable) {
      yield callConfig.call.ModalContext.openModal(MODAL_TYPE.timeUnavailable, {
        noCloseOverlay: true,
      });
    } else {
      const nextAvailable = yield select(getNextAvailableTime);

      yield callConfig.call.ModalContext.openModal(MODAL_TYPE.dialog, {
        ...MENU_EXPIRED_MODAL_COPY,
        noCloseOverlay: true,
        isAlert: true,
        isError: true,
        onConfirm: () => {
          window.location.reload();
        },
      });

      yield put(
        updateOrderRequest({
          restaurantId: updatedRestaurant.id,
          fulfillmentMethod: nextAvailable.fulfillMethod,
          when: nextAvailable.nextAvailableTime,
        })
      );
    }
  } else if (
    some(orderErrors, { code: TIMESLOT_ORDER_THROTTLED }) &&
    (!orderMeta.hasHandledOrderThrottling ||
      type === submitOrderXHR.failure.TYPE)
  ) {
    // Update restaurant to get valid time slots
    yield request({
      requestAction: fetchRestaurantXHR,
      path: `restaurant/${restaurant.id}`,
    });

    const error = orderErrors.filter(
      (orderError) => orderError.code === TIMESLOT_ORDER_THROTTLED
    );
    const isDeliveryAheadAvailable = get(
      restaurant.order_ahead,
      `is_delivery_ahead_available`
    );
    // FIXME: see CN-19150 - This is a temp fix for order throttling until BE work is complete
    const isDeliveryAndDeliveryAheadUnavailable =
      !isDeliveryAheadAvailable &&
      fulfillMethod === FULFILLMENT_METHODS.delivery;
    // FIXME: (CN-19150) checks next avail time for the forced pickup fulfillment if delivery ahead not avail
    const nextAvailable = yield select(getNextAvailableTime);

    yield put(updateOrderThrottlingMeta());
    yield put(
      updateOrderRequest({
        // FIXME: (CN-19150) force user to use pickup as fulfillment method if delivery ahead unavail
        fulfillmentMethod: isDeliveryAndDeliveryAheadUnavailable
          ? FULFILLMENT_METHODS.pickup
          : fulfillMethod,
        when: isDeliveryAndDeliveryAheadUnavailable
          ? nextAvailable.nextAvailableTime
          : error[0].next_available_time,
      })
    );

    const when = yield select(getFulfillmentTime);

    if (!when && !error[0].next_available_time) {
      yield callConfig.call.ModalContext.openModal(MODAL_TYPE.dialog, {
        title: "We're a little busy right now.",
        confirmLabel: 'Okay',
        noCloseOverlay: true,
        isAlert: true,
        isError: true,
      });
    } else {
      yield callConfig.call.ModalContext.openModal(
        MODAL_TYPE.orderingUnavailable,
        {
          isDeliveryAndDeliveryAheadUnavailable,
          isOrderThrottleError: true,
          nextAvailableWhen: error[0].next_available_time,
          when,
          noCloseOverlay: true,
        }
      );
    }
  } else if (
    some(orderErrors, { code: ADDRESS_OUTSIDE_DELIVERY_AREAS }) ||
    (some(orderErrors, { code: JSON_REQUIRED_FIELD_MISSING }) &&
      (some(orderErrors, { field: CITY_ERROR_FIELD }) ||
        some(orderErrors, { field: STREET_ERROR_FIELD })))
  ) {
    yield callConfig.call.ModalContext.openModal(
      MODAL_TYPE.fulfillmentDetails,
      {
        fulfillmentMethod: fulfillMethod,
        noCloseOverlay: isDeliveryOnly,
        shouldDefaultToPickup: !isDeliveryOnly,
      }
    );
  } else if (
    some(orderErrors, { code: CURBSIDE_INVALID_VEHICLE_COLOR }) ||
    some(orderErrors, { code: CURBSIDE_INVALID_VEHICLE_TYPE }) ||
    some(orderErrors, { code: CURBSIDE_MISSING_VEHICLE }) ||
    some(orderErrors, { code: CURBSIDE_MISSING_VEHICLE_COLOR }) ||
    some(orderErrors, { code: CURBSIDE_MISSING_VEHICLE_TYPE })
  ) {
    yield put(setVehicleRequest()); // reset vehicle info on any of these errors
    yield callConfig.call.ModalContext.openModal(
      MODAL_TYPE.fulfillmentDetails,
      {
        fulfillmentMethod: FULFILLMENT_METHODS.curbside,
      }
    );
  } else if (
    some(orderErrors, { code: ITEM_EXPIRED }) ||
    some(orderErrors, { code: MOD_EXPIRED }) ||
    some(orderErrors, { code: MODS_INVALID_FOR_ITEM })
  ) {
    const items = yield select(getItemsInOrder);

    // start ITEM_EXPIRED
    // get array of item ids
    const badItems =
      orderErrors
        .filter((error) => error.code === '34002' || error.code === '34005')
        .map((error) => error.value) || [];
    // get item data
    const rejectedItems = filter(
      items,
      (item) => includes(badItems, item.sizeId) || includes(badItems, item.id)
    );
    // end ITEM_EXPIRED

    // start MOD_EXPIRED
    // array of mod ids
    const badMods =
      orderErrors
        .filter((error) => error.code === MOD_EXPIRED)
        .map((error) => error.value) || [];
    // get full item data
    const rejectedModItems = [];
    items.forEach((item) => {
      item.modifierCategories.forEach((modCat) => {
        badMods.forEach((badMod) => {
          if (
            modCat.modifiers.includes(badMod) &&
            !rejectedItems.includes(item.id) // dont add again if item is already in rejected items
          )
            rejectedModItems.push(item);
        });
      });
    });
    // get array of parent ids for bad mods
    const badModItems = rejectedModItems.map((item) => item.id);
    // end MOD_EXPIRED

    yield callConfig.call.ModalContext.openModal(MODAL_TYPE.itemExpired, {
      rejectedItems: rejectedItems.concat(rejectedModItems),
    });

    yield orderRequestHelper(removeRejectedItems, badItems.concat(badModItems));
  } else if (some(orderErrors, { code: MENU_EXPIRED })) {
    yield callConfig.call.ModalContext.openModal(MODAL_TYPE.dialog, {
      ...MENU_EXPIRED_MODAL_COPY,
      noCloseOverlay: true,
      isAlert: true,
      isError: true,
      onConfirm: () => {
        window.location.reload();
      },
    });
  } else if (
    some(orderErrors, { code: INVALID_PROMO_CODE }) ||
    some(orderErrors, { code: BETTER_DEAL_APPLIED })
  ) {
    // If promo errors, clear out promo code
    yield put(resetPromoCodeRequest(null, { disableValidation: true }));
  } else if (
    some(orderErrors, { code: TIP_TOO_BIG }) ||
    some(orderErrors, { code: TIP_AND_TIP_PERCENTAGE_EXCLUSIVE })
  ) {
    yield callConfig.call.ModalContext.openModal(MODAL_TYPE.dialog, {
      title: "Let's Take a Second Look.",
      message: errorMessage,
      confirmLabel: 'Go Back',
      noCloseOverlay: true,
      isAlert: true,
      isError: true,
    });
  } else if (
    some(orderErrors, { code: DINE_IN_TABLE_NOT_ALLOWED }) ||
    some(orderErrors, { code: DINE_IN_TABLE_REQUIRED })
  ) {
    yield callConfig.call.ModalContext.openModal(MODAL_TYPE.dialog, {
      message: errorMessage,
      onConfirm: () => {
        window.location.reload();
      },
      noCloseOverlay: true,
      isAlert: true,
    });

    if (some(orderErrors, { code: DINE_IN_TABLE_NOT_ALLOWED })) {
      yield put(resetTableNameRequest());
    }
  } else if (some(orderErrors, { code: UNAUTHORIZED })) {
    logException({
      name: 'Lost session on submit order',
      message: `err: ${orderErrors[0].message}`,
    });
  } else if (some(orderErrors, { code: CVV_REQUIRED })) {
    yield callConfig.call.ModalContext.openModal(MODAL_TYPE.dialog, {
      message: genericDeclineMsg,
      title: genericDeclineTitle,
      confirmLabel: 'Back to Checkout',
      isAlert: true,
    });
  } else if (some(orderErrors, { code: RESTAURANT_NOT_LIVE })) {
    yield callConfig.call.ModalContext.openModal(MODAL_TYPE.dialog, {
      message: errorMessage,
      isAlert: true,
      onConfirm: () => {
        if (isOnPage('checkout')) {
          window.location.reload();
        }
      },
    });

    yield put(resetOrder());
  } else if (some(orderErrors, { code: PREPTIME_CHANGED })) {
    yield callConfig.call.ModalContext.openModal(MODAL_TYPE.dialog, {
      title: 'New Ready Time',
      message: errorMessage,
      confirmLabel: 'Okay',
      isAlert: true,
      onConfirm: () => {
        window.location.reload();
      },
      noCloseOverlay: true,
    });
  } else if (some(orderErrors, { code: PREPTIME_REMOVED })) {
    yield callConfig.call.ModalContext.openModal(MODAL_TYPE.dialog, {
      title: 'Order Update',
      message: errorMessage,
      confirmLabel: 'Okay',
      isAlert: true,
      onConfirm: () => {
        window.location.reload();
      },
      noCloseOverlay: true,
    });
  } else if (
    some(orderErrors, { code: INVALID_MISSING_PHONE_NUMBER }) ||
    some(orderErrors, { code: LENGTH_BELOW_MIN })
  ) {
    yield callConfig.call.ModalContext.openModal(MODAL_TYPE.confirmAccount, {
      noCloseOverlay: true,
    });
  } else if (type === submitOrderXHR.failure.TYPE && !is5xxError) {
    yield callConfig.call.ModalContext.openModal(MODAL_TYPE.dialog, {
      message: errorMessage
        ? `${errorMessage}`
        : 'Sorry about that. Go back and try again.',
      isAlert: true,
      isError: true,
      noCloseOverlay: true,
    });
  } else if (some(orderErrors, { code: RESTAURANT_DOES_NOT_ALLOW_TIP })) {
    yield put(resetTipsRequest());
  } else if (
    some(orderErrors, { code: RESTAURANT_DOES_NOT_ALLOW_SINGLE_USE })
  ) {
    yield put(
      updateOrderRequest({
        include_single_use_items: false,
      })
    );

    yield request({
      requestAction: fetchRestaurantXHR,
      path: `restaurant/${restaurant.id}`,
    });
  }
}

export function* handleAddItemRequest({
  payload: itemDetails,
  meta: trackingId,
}) {
  const orderItemId = trackingId || uuidv4();

  yield orderRequestHelper(
    addItemToOrderSuccess,
    {
      ...itemDetails,
      tracking_id: orderItemId,
    },
    {
      itemId: itemDetails.id,
      trackingId: orderItemId,
    }
  );
}

function* handleUpdateOrderRequest({ payload, meta }) {
  if (
    payload &&
    Object.prototype.hasOwnProperty.call(payload, 'when') &&
    payload.when === undefined
  ) {
    yield orderRequestHelper(updateOrderFailure);
  } else {
    const fulfillMethod = yield select(getFulfillmentMethod);
    if (payload) {
      const isTipEnabled = yield select(
        getIsTippingEnabled,
        payload.fulfillmentMethod || fulfillMethod
      );
      if (!isTipEnabled) {
        yield put(resetTipsRequest());
      }
    }

    yield orderRequestHelper(updateOrderSuccess, payload, meta);
  }
}

export function* handleUpdateOrderItemRequest({
  payload: itemData,
  meta: itemIndex,
}) {
  yield orderRequestHelper(updateOrderItemSuccess, itemData, {
    index: itemIndex,
  });
}

export function* handleResetWhen() {
  const currentWhen = yield select(getFulfillmentTime);
  const { nextAvailableTime } = yield select(getNextAvailableTime);

  if (currentWhen !== nextAvailableTime) {
    yield put(setWhenRequest(nextAvailableTime));
  }
}

export function* handleSubmitOrder({ payload }) {
  logAnalyticsEvent({
    eventName: ANALYTICS_EVENT_NAME.selectX,
    attributes: {
      source: 'checkout',
      name: 'place_order',
    },
  });
  const orderPayload = yield select(buildSubmitOrderPayload(payload));
  yield request({
    requestAction: submitOrderXHR,
    path: 'order',
    params: orderPayload,
    method: 'POST',
    apiVersion: ORDER_SUBMIT_API_VERSION,
  });
}

export function* handleSubmitYelpOrder() {
  const orderPayload = yield select(buildSubmitOrderPayload());

  const yelpData = getYelpData();

  orderPayload.meta = {
    marketplace: {
      id: '1',
      x_id: yelpData.opportunity_token,
    },
  };

  // For pickup orders, we do not have a customer, but a customer key must exist in the payload
  if (orderPayload.fulfill_method === FULFILLMENT_METHODS.pickup) {
    orderPayload.customer = null;
  }

  yield request({
    requestAction: submitOrderXHR,
    path: 'yelp',
    params: orderPayload,
    method: 'POST',
    apiVersion: ORDER_SUBMIT_API_VERSION,
  });

  const restaurant = yield select(getRestaurantDetails);
  const order = yield select(getOrderData);

  const menu = callConfig.call.MenuContext.menu; // eslint-disable-line prefer-destructuring

  const attributes = {
    menu_id: menu.id,
    ...pick(orderAttributes(order), [
      A.orderAheadDatetime,
      A.orderIsAsap,
      A.orderTotal,
      A.orderType,
      A.hasDeliveryFee,
      A.deliveryFeeAmount,
      A.hasMiscFee,
      A.miscFeeAmount,
      A.miscFeeName,
    ]),
    ...pick(restaurantAttributes(restaurant), [
      A.restaurantBrandId,
      A.restaurantLocationCategory,
      A.restaurantLocationId,
      A.restaurantLocationName,
    ]),
  };

  logAnalyticsEvent({
    eventName: ANALYTICS_EVENT_NAME.proceedToPayment,
    eventType: ANALYTICS_EVENT_TYPES.Other,
    attributes,
  });

  // If running the Yelp theme, we must redirect back to Yelp
  // once the order Id is known
  if (isYelp) {
    const yelpOrderId = yield select(getYelpOrderId);

    if (yelpOrderId) {
      checkoutRedirect(yelpOrderId);
    }
  }
}

export function* handleFetchOrderStatus({ payload }) {
  if (!payload) return;
  const cacheBustDate = new Date();
  const body = {
    time: cacheBustDate.getTime(),
  };

  yield request({
    requestAction: fetchOrderStatusXHR,
    path: `order/${payload}`,
    params: body,
    apiVersion: ORDER_DETAILS_API_VERSION,
  });
}

export function* handleFetchOrderStatusSuccess({ payload }) {
  if (payload.status === ORDER_STATUSES.declined) {
    // Reload customer on declined orders to get updated billing/delivery info
    callConfig.call.UserContext.handleFetchUserProfile();

    const orderCard = get(payload, 'customer.billing.card', null);
    const orderCardId = orderCard?.id;

    setSubmitOrderCount(orderCardId);

    yield callConfig.call.ModalContext.openModal(MODAL_TYPE.dialog, {
      message: genericDeclineMsg,
      title: genericDeclineTitle,
      confirmLabel: 'Back to Checkout',
      isAlert: true,
    });

    // For both delivery and billing, if declined order, make sure ids are set to last submitted ids as
    // to not create duplicates
    if (payload.fulfill_method === FULFILLMENT_METHODS.delivery) {
      const orderDeliveryId = get(
        payload,
        'customer.delivery.address.id',
        null
      );
      const selectedAddress = getSelectedAddress();

      yield setSelectedAddress({ ...selectedAddress, id: orderDeliveryId });
    }

    if (orderCard?.is_visible) {
      yield callConfig.call.UserContext.setSelectedCardId(orderCardId);
    } else {
      yield callConfig.call.UserContext.setSelectedCardId(null);
    }
  }
}

export function* handleSubmitOrderSuccess({ payload }) {
  const newAddressId = get(payload, 'customer.delivery.address.id');
  const selectedAddress = yield select(getSelectedAddress);
  if (newAddressId) {
    yield setSelectedAddress({
      ...selectedAddress,
      id: newAddressId,
    });
  }
}

function getLastSubmittedAddressId(payload) {
  const addresses = get(payload, 'customer.delivery.addresses', []);
  const lastSubmittedAddress = addresses.find(
    (address) => address.is_last_submitted
  );
  const lastSubmittedAddressId =
    lastSubmittedAddress && lastSubmittedAddress.id;
  return lastSubmittedAddressId;
}

export function getLastSubmittedIds(payload) {
  return {
    addressId: getLastSubmittedAddressId(payload),
  };
}

export function* handleSubmitOrderFailure({ payload }) {
  const { addressId } = getLastSubmittedIds(payload); // todo change method name

  // https://chownow.atlassian.net/browse/CN-14042
  // Use the last submitted address ID from the response and store it
  // to prevent duplicate addresses being created
  if (addressId) {
    const selectedAddress = getSelectedAddress();
    selectedAddress.id = addressId;
    setSelectedAddress(selectedAddress);
  }

  yield callConfig.call.UserContext.handleFetchUserProfile();
}

export function* handleFetchOrderStatusFailure({ payload }) {
  if (some(payload.errors, { code: UNAUTHORIZED })) {
    yield logException({
      name: 'Lost session on submit order',
      message: `err: ${payload.errors[0].message}`,
    });
  }
}

export function* handleOrderCompleted() {
  yield all([put(resetTimer()), put(resetOrder())]);
  yield callConfig.call.UserContext.handleFetchUserProfile();
}

function* handleSetPromoCodeRequest({ payload, meta }) {
  yield orderRequestHelper(setPromoCodeSuccess, payload, meta);
}

function* handleResetPromoCodeRequest({ payload, meta }) {
  yield orderRequestHelper(resetPromoCodeSuccess, payload, meta);
}

function* handleManagedDeliveryTipRequest({ payload: amount }) {
  const payload = {
    managed_delivery_tip_amount: parseFloat(amount),
    managed_delivery_tip_percentage: null,
  };
  yield orderRequestHelper(setManagedDeliveryTipSuccess, payload);
}

function* handleRestaurantTipRequest({ payload: amount }) {
  const payload = {
    restaurant_tip_amount: parseFloat(amount),
    restaurant_tip_percentage: null,
  };
  yield orderRequestHelper(setRestaurantTipSuccess, payload);
}

export function* fetchOrderDetails({ payload }) {
  const { orderId, guid = '' } = payload;

  yield request({
    requestAction: fetchOrderDetailsXHR,
    path: `order/${orderId}${guid && `?guid=${guid}`}`,
    method: 'GET',
    apiVersion: ORDER_DETAILS_API_VERSION,
  });
}

function* handleResetTimer() {
  yield callConfig.call.ModalContext.closeModal();
}

function* handleResetSubmitOrderRequest() {
  yield orderRequestHelper(resetSubmitOrderSuccess);
}

function* handleRemoveItemRequest({ payload: orderItem, meta: trackingId }) {
  yield orderRequestHelper(removeOrderItemSuccess, null, {
    orderItem,
    trackingId,
  });
}

function* handleRemoveItemSuccess() {
  // Reset tip on last item removal
  const itemsInOrder = yield select(getItemsInOrder);

  if (!itemsInOrder.length) {
    yield put(resetTipsRequest());
  }
}

function* handleSetWhenRequest({ payload }) {
  yield orderRequestHelper(setWhenSuccess, payload);
}

function* handleSetVehicleRequest({ payload }) {
  yield orderRequestHelper(setVehicleSuccess, payload);
}

function* handleSetTableNameRequest({ payload, meta }) {
  yield orderRequestHelper(setTableNameSuccess, payload, meta);
}

function* handleResetTableNameRequest({ payload, meta }) {
  yield orderRequestHelper(resetTableNameSuccess, payload, meta);
}

function* handleStartOrderRequest({ payload }) {
  if (
    payload.restaurantId &&
    payload.fulfillmentMethod &&
    payload.when !== undefined
  ) {
    yield orderRequestHelper(startOrderSuccess, payload);
  } else {
    yield orderRequestHelper(startOrderFailure);
  }
}

export function getItemHasNestedMods(modifierCategories) {
  // I am quieting the linter here as I think this is much more readable with explicit return statements
  // $5 to whoever thinks of a better way to do this check.
  // eslint-disable-next-line arrow-body-style
  return modifierCategories.some((modCatOuterLevel) => {
    // eslint-disable-next-line arrow-body-style
    return modCatOuterLevel.modifiers.some((modifier) => {
      return modifier.modifier_categories?.length;
    });
  });
}

function* sendItemEvent({ payload }) {
  const {
    categoryName,
    id,
    image,
    isOneClick,
    itemIndex,
    quantity,
    price,
    name,
    modifierCategories,
  } = payload;
  // increase index by 1 for mParticle logging so first item starts at 1 instead of 0
  const orderItemIndex = itemIndex + 1;

  const itemHasNestedModifiers = getItemHasNestedMods(modifierCategories);
  const restaurant = yield select(getRestaurantDetails);
  const menu = callConfig.call.MenuContext.menu; // eslint-disable-line prefer-destructuring
  const order = yield select(getOrderData);
  const isCanadian = yield callConfig.call.CompanyContext.isCanadian;

  const menuItemSource =
    categoryName === REORDER_CATEGORY_NAME &&
    (isOneClick ? 'one_click_reorder' : 'reorder_carousel');

  const product = [
    createAnalyticsProduct(
      name,
      id,
      price.toFixed(2),
      quantity,
      categoryName,
      isCanadian ? 'CAD' : 'USD'
    ),
  ];

  const attributes = {
    item_has_nested_mods: itemHasNestedModifiers,
    item_has_photo: !!image,
    menu_id: menu.id,
    menu_item_source: menuItemSource,
    menu_item_location: !!menuItemSource && orderItemIndex, // only return index if reorder item
    is_delete: false,
    is_direct_to_marketplace_redirect: getIsDirectToMpRedirect(),
    is_embedded_site: window.top !== window,
    is_cart_update: false,
    order_type: order.fulfillmentMethod,
    ...pick(orderAttributes(order), [
      A.orderAheadDatetime,
      A.orderIsAsap,
      A.orderType,
    ]),
    ...pick(restaurantAttributes(restaurant), [
      A.restaurantBrandId,
      A.restaurantLocationCategory,
      A.restaurantLocationId,
      A.restaurantLocationName,
    ]),
  };
  logAnalyticsCommerceEvent(
    ANALYTICS_EVENT_NAME.addToCart,
    product,
    attributes
  );
}

export function* handleFetchYelpOpportunity({ payload }) {
  const apiUrl = `yelp/opportunity/${payload.opportunityToken}`;

  return yield request({ requestAction: yelpOpportunityXHR, path: apiUrl });
}

export function* handleFetchYelpOpportunitySuccess({ payload }) {
  const { fulfillment_method: fulfillmentMethod, address } = payload;

  if (fulfillmentMethod === FULFILLMENT_METHODS.delivery) {
    // Here we basically re-play the same actions as in
    // src/components/ModalWrapper/AddressModal/index.js handleUpdateAddress()
    // when the user manually enters their address + selects delivery
    yield setSelectedAddress({
      ...address,
      types: ['street_address'],
      delivery_instructions: null,
    });

    return yield put(
      updateOrderRequest({
        fulfillmentMethod: FULFILLMENT_METHODS.delivery,
        when: null,
      })
    );
  }

  // Otherwise we actively set pickup/takeout since the "default"
  // behavior is to use the last delivery order address
  // regardless of what the user selected on Yelp
  return yield put(
    updateOrderRequest({
      fulfillmentMethod: FULFILLMENT_METHODS.pickup,
      when: null,
    })
  );
}

export function* handleResetTipsRequest() {
  yield orderRequestHelper(resetTipsSuccess);
}

export function* orderSaga() {
  yield takeLatest(
    [
      addItemToOrderSuccess.TYPE,
      removeOrderItemSuccess.TYPE,
      updateOrderItemSuccess.TYPE,
      initiateCheckout.TYPE,
      // in case user data changes
      setPromoCodeSuccess.TYPE,
      resetPromoCodeSuccess.TYPE,
      resetTipsSuccess.TYPE,
      setManagedDeliveryTipSuccess.TYPE,
      setRestaurantTipSuccess.TYPE,
      setTableNameSuccess.TYPE,
      updateOrderSuccess.TYPE,
      triggerValidate.TYPE,
    ],
    handleValidateOrder
  );
  yield takeLatest(
    [validateOrderXHR.failure.TYPE, submitOrderXHR.failure.TYPE],
    handleInvalidOrder
  );
  yield takeLatest(addItemToOrderRequest.TYPE, handleAddItemRequest);
  yield takeEvery(addItemToOrderSuccess.TYPE, sendItemEvent);
  yield takeEvery(fetchOrderDetailsXHR.request.TYPE, fetchOrderDetails);
  yield takeLatest(fetchOrderStatusXHR.request.TYPE, handleFetchOrderStatus);
  yield takeLatest(
    fetchOrderStatusXHR.success.TYPE,
    handleFetchOrderStatusSuccess
  );
  yield takeLatest(
    fetchOrderStatusXHR.failure.TYPE,
    handleFetchOrderStatusFailure
  );
  yield takeEvery(setOrderCompleted.TYPE, handleOrderCompleted);
  yield takeLatest(setPromoCodeRequest.TYPE, handleSetPromoCodeRequest);
  yield takeLatest(setTableNameRequest.TYPE, handleSetTableNameRequest);
  yield takeLatest(
    setManagedDeliveryTipRequest.TYPE,
    handleManagedDeliveryTipRequest
  );
  yield takeLatest(setRestaurantTipRequest.TYPE, handleRestaurantTipRequest);
  yield takeLatest(setVehicleRequest.TYPE, handleSetVehicleRequest);
  yield takeLatest(setWhenRequest.TYPE, handleSetWhenRequest);
  yield takeLatest(startOrderRequest.TYPE, handleStartOrderRequest);
  yield takeLatest(submitOrderXHR.request.TYPE, handleSubmitOrder);
  yield takeLatest(submitOrderXHR.success.TYPE, handleSubmitOrderSuccess);
  yield takeLatest(submitOrderXHR.failure.TYPE, handleSubmitOrderFailure);

  yield takeLatest(tipAdjustmentXHR.request.TYPE, handleTipAjustment);
  yield takeLatest(tipAdjustmentXHR.success.TYPE, handleTipAjustmentSuccess);

  yield takeLatest(submitYelpOrderXHR.request.TYPE, handleSubmitYelpOrder);
  yield takeLatest(removeOrderItemRequest.TYPE, handleRemoveItemRequest);
  yield takeLatest(removeOrderItemSuccess.TYPE, handleRemoveItemSuccess);
  yield takeLatest(resetTableNameRequest.TYPE, handleResetTableNameRequest);
  yield takeLatest(resetWhen.TYPE, handleResetWhen);
  yield takeLatest(resetSubmitOrderRequest.TYPE, handleResetSubmitOrderRequest);
  yield takeLatest(resetPromoCodeRequest.TYPE, handleResetPromoCodeRequest);
  yield takeLatest(resetTimer.TYPE, handleResetTimer);
  yield takeLatest(resetTipsRequest.TYPE, handleResetTipsRequest);
  yield takeLatest(updateOrderRequest.TYPE, handleUpdateOrderRequest);
  yield takeLatest(updateOrderItemRequest.TYPE, handleUpdateOrderItemRequest);
  yield takeLatest(validateReorderXHR.request.TYPE, handleValidateReorder);
  yield takeLatest(
    validateReorderXHR.success.TYPE,
    handleValidateReorderSuccess
  );
  yield takeLatest(yelpOpportunityXHR.request.TYPE, handleFetchYelpOpportunity);
  yield takeLatest(
    yelpOpportunityXHR.success.TYPE,
    handleFetchYelpOpportunitySuccess
  );
}

export default [orderSaga];
