/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';

import useIsMobile from 'hooks/useIsMobile';
import usePlatform from 'hooks/usePlatform';

import AuthMenu from 'components/AuthMenu';
import SideMenu from 'components/SideMenu';

interface NavMenuProps {
  handleLocationSwitch: (id: string) => void;
  isMultiLocation: boolean;
}

function NavMenu({ handleLocationSwitch, isMultiLocation }: NavMenuProps) {
  const isMobile = useIsMobile();
  const { isChowNowPlatform } = usePlatform();

  return (
    <>
      {isChowNowPlatform && (
        <>
          {isMobile ? (
            <SideMenu
              onLocationsBack={handleLocationSwitch}
              isMultiLocation={isMultiLocation}
            />
          ) : (
            <AuthMenu />
          )}
        </>
      )}
    </>
  );
}

export default NavMenu;
