/* eslint-disable complexity */
import { parse, stringify } from 'query-string';
import { apiRequest } from '@chownow/cn-web-utils/api';

import { DEPLOY_ENVIRONMENTS, MARKETPLACE_ORIGINS } from 'helpers/constants';
import { getCnChannel, getIsSessionStorageAllowed } from 'helpers/customer';
import {
  logAnalyticsEvent,
  logMessage,
  ANALYTICS_EVENT_NAME,
} from 'helpers/loggers';
import { isDirectUrl, getHostnameForMarketplace } from 'helpers/url';

/**
 * Returns a boolean that describes if a restaurant is live for Marketplace (formerly Discover)
 * @param locationID {string} restaurant location ID
 * @returns {boolean} representing live for discover status
 */
export async function getRestaurantIsLiveForMarketplace(locationID) {
  try {
    const request = apiRequest(`restaurant/${locationID}`);
    const response = await fetch(request.endpoint);
    const restaurant = await response.json();
    return !!restaurant.is_discover_live;
  } catch {
    return false;
  }
}

/**
 * Returns a boolean that describes if a restaurant is not live for Direct but live on Marketplace
 * @param locationID {string} restaurant location ID
 * @param resto {object} restaurant object
 * @returns {Promise<boolean>} representing live for direct status
 */
export async function shouldRedirectToMarketplace(locationID, resto) {
  try {
    // Avoid redirecting on local. Because local dev hostnames are shared between OD and Marketplace,
    // This would cause an infinite loop.
    const deployEnv = process.env.REACT_APP_DEPLOY_ENV;
    if (deployEnv === DEPLOY_ENVIRONMENTS.local) return false;
    if (!isDirectUrl()) return false;

    const search = window.location?.search;
    const cnChannel = (search && parse(search).cn_channel) || getCnChannel();
    // Don't redirect if coming from OBN channel
    if (cnChannel) return false;

    let restaurant = resto;

    if (!restaurant) {
      const request = apiRequest(`restaurant/${locationID}`);
      const response = await fetch(request.endpoint);
      restaurant = await response.json();
    }

    if (!Object.prototype.hasOwnProperty.call(restaurant, 'is_direct_live'))
      return false;

    return !restaurant.is_direct_live;
  } catch (e) {
    logMessage(`Failed to redirect to MP: ${e}`);
    return false;
  }
}

export function getIsDirectToMpRedirect() {
  const isSessionStorageAllowed = getIsSessionStorageAllowed();
  let isDirectToMpRedirect = '';

  if (isSessionStorageAllowed) {
    isDirectToMpRedirect = JSON.parse(
      sessionStorage.getItem('cn_directToMpRedirect')
    );
  }

  return isDirectToMpRedirect || false;
}

export function setIsDirectToMpRedirect() {
  const isSessionStorageAllowed = getIsSessionStorageAllowed();

  if (isSessionStorageAllowed) {
    sessionStorage.setItem('cn_directToMpRedirect', true);
  }
}

export function redirectToMarketplaceMenu({
  restoId,
  hqId,
  orderId,
  shouldReplaceUrl,
  source,
}) {
  const searchParams = parse(window.location.search);
  searchParams.is_mp_redirect = true;

  const domain = getHostnameForMarketplace();
  const marketplaceUrl = `https://${domain}/order/${
    orderId ? `history/${orderId}` : `${hqId}/locations/${restoId}`
  }?${stringify(searchParams)}`;

  setIsDirectToMpRedirect();

  logAnalyticsEvent({
    eventName: ANALYTICS_EVENT_NAME.redirectToMarketplaceMenu,
    attributes: {
      restaurant_location_id: restoId,
      source,
    },
  });

  if (shouldReplaceUrl) {
    return window.location.replace(marketplaceUrl);
  }
  return window.open(marketplaceUrl, '_blank');
}

/**
 * Evaluate whether a URL qualifies for a redirect to Marketplace from OD
 * Qualifying URLs
 * - contain a query with the `cn_instagram` or `cn_facebook` value
 * - Originated on an Order Direct app
 * - Are aiming to hit a menu
 * @param location {object} browser location object with origin, pathname and search keys
 * @returns {boolean} evaluate whether a URL qualifies for an Instagram redirect
 */
export function getURLRequiresRedirect({ pathname, origin, search }) {
  const deployEnv = process.env.REACT_APP_DEPLOY_ENV;
  const INSTAGRAM_QUERY = 'cn_instagram';
  const FACEBOOK_QUERY = 'cn_facebook';
  const LOCATIONS_REGEX = /locations\/\d/gm;
  // Avoid redirecting on local. Because local dev hostnames are shared between OD and Marketplace,
  // This would cause an infinite loop.
  if (deployEnv === DEPLOY_ENVIRONMENTS.local) return false;

  if (
    (search.includes(INSTAGRAM_QUERY) || search.includes(FACEBOOK_QUERY)) &&
    !origin.includes(MARKETPLACE_ORIGINS.prod) &&
    !origin.includes(MARKETPLACE_ORIGINS.qa) &&
    pathname.match(LOCATIONS_REGEX)
  ) {
    return true;
  }
  return false;
}

// If a user lands on ordering with nothing in the URL path, we ultimately want to redirect them
// to the Marketplace homepage
export function getUrlQualifiesForRedirectToMarketplace({ url }) {
  const urlObject = new URL(url);
  const isRouteWithoutPath = urlObject?.pathname === '/';

  return isRouteWithoutPath;
}

export function redirectToMarketplaceHomepage() {
  const redirectTo = getHostnameForMarketplace();
  window.location.replace(`https://${redirectTo}`);
}
