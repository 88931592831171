import useFeatureFlagRequest from 'hooks/useFeatureFlagRequest';

import { FEATURE_FLAGS } from 'helpers/constants';

// FLAG FOR https://chownow.atlassian.net/browse/CN-38525

function useShowPostOrderAds(restaurantId) {
  const { showFeature: canShowPostOrderAds } = useFeatureFlagRequest({
    doLoad: !!restaurantId,
    flag: FEATURE_FLAGS.showPostOrderAds,
    customAttributes: {
      restaurantId,
    },
  });

  return canShowPostOrderAds;
}

export default useShowPostOrderAds;
