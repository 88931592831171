// ticket to fix: https://chownow.atlassian.net/browse/CN-23527
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { partial } from 'lodash';

import { addressType } from 'helpers/prop-types';
import { BACK_BTN_LABELS, KEY_VALUES } from 'helpers/constants';

import DropdownWrapper from 'components/DropdownWrapper';

import { ReactComponent as IconLeftArrow } from 'images/icon-arrow-left.svg';
import { ReactComponent as IconLocationPin } from 'images/icon-location-pin.svg';
import { ReactComponent as IconLocationPinFilled } from 'images/icon-location-pin-filled.svg';

import styles from './styles.module.scss';

function LocationsMenu({
  closeLocationDropdown,
  handleLocationSwitch,
  locations,
  restaurantId,
}) {
  const [isItemActive, setIsItemActive] = useState(null);

  function handleKeyPressBack(event) {
    if (event.key === KEY_VALUES.enter) {
      handleLocationSwitch();
    }
  }

  function handleKeyPressLocation(event, locationId) {
    if (event.key === KEY_VALUES.enter) {
      // onKeyDown won't work with partial so removing for handleLocationSwitch (see line 67)
      handleLocationSwitch(locationId);
    }
  }

  return (
    <DropdownWrapper handleClickOutside={closeLocationDropdown}>
      <ul>
        <li
          tabIndex={0}
          className={classNames(styles.listItem, styles.backButton)}
          onClick={() => handleLocationSwitch()}
          onKeyDown={(event) => handleKeyPressBack(event)}
          aria-label="Back to locations map"
          role="button"
          data-testid="BackToMulti"
        >
          <IconLeftArrow className={styles.icon} aria-hidden="true" />
          {BACK_BTN_LABELS.locations}
        </li>
        <div>
          {locations.map((location) => {
            if (location.id === restaurantId) return null;

            return (
              <li
                tabIndex={0}
                className={styles.listItem}
                key={location.id}
                onClick={partial(handleLocationSwitch, location.id)}
                onKeyDown={(event) =>
                  handleKeyPressLocation(event, location.id)
                }
                onMouseDown={() => setIsItemActive(location.id)}
                onMouseUp={() => setIsItemActive(null)}
                aria-label={`Select ${location.name}`}
                role="button"
              >
                <div className={styles.itemNameWrapper}>
                  {location.id === isItemActive ? (
                    <IconLocationPinFilled className={styles.icon} />
                  ) : (
                    <IconLocationPin className={styles.icon} />
                  )}
                  <span className={styles.itemName}>{location.name}</span>
                </div>
                <div
                  className={styles.itemAddress}
                  title={location.address.formatted_address}
                >
                  {location.address.formatted_address}
                </div>
              </li>
            );
          })}
        </div>
      </ul>
    </DropdownWrapper>
  );
}

LocationsMenu.propTypes = {
  closeLocationDropdown: PropTypes.func,
  handleLocationSwitch: PropTypes.func,
  locations: PropTypes.arrayOf(addressType),
  restaurantId: PropTypes.string,
};

LocationsMenu.defaultProps = {
  closeLocationDropdown: () => {},
  handleLocationSwitch: () => {},
  locations: [],
  restaurantId: undefined,
};

export default LocationsMenu;
