/* eslint-disable complexity */
import React from 'react';
import classNames from 'classnames';
import { useParams, useHistory } from 'react-router-dom';

import { buildRoute, ROUTES } from 'helpers/routes';
import { isOnLocations, isOnPage } from 'helpers/url';

import useIsMobile from 'hooks/useIsMobile';
import usePlatform from 'hooks/usePlatform';

import LocationsMenu from 'components/LocationsMenu';

import { ReactComponent as IconCaretDown } from 'images/icon-caret-down.svg';

import styles from './styles.module.scss';

interface RestaurantNameProps {
  formattedAddress?: string;
  handleLocationSwitch: (id: string) => void;
  locations?: object[];
  restaurantName: string;
  setShowLocationDropdown: (show: boolean) => void;
  showLocationDropdown: boolean;
}

function RestaurantName({
  formattedAddress,
  handleLocationSwitch,
  locations = [],
  restaurantName,
  setShowLocationDropdown,
  showLocationDropdown,
}: RestaurantNameProps) {
  const history = useHistory();
  const { hqId, restaurantId } = useParams();

  const isMobile = useIsMobile();
  const { isMarketplacePlatform, isDirectPlatform } = usePlatform();

  const isLocations = isOnLocations();
  const isOnMembership = isOnPage('membership');

  const canClickLocationDropdown = isDirectPlatform && locations?.length > 1;

  function handleAddressClick() {
    if (isOnMembership) {
      history.push(buildRoute({ hqId, restaurantId, route: ROUTES.menu }));
    }

    setShowLocationDropdown(true);
  }

  return (
    <>
      <div
        className={classNames({
          [styles.nameWrapper]: !isLocations,
          [styles.showOnMobile]: isMarketplacePlatform || !isLocations,
          [styles.noBorder]: isMarketplacePlatform,
        })}
      >
        <div className={styles.restaurantName} data-testid="CompanyName">
          {restaurantName}
        </div>
      </div>
      {!isLocations && (
        <button
          className={classNames(styles.addressWrapper, {
            [styles.noClick]: !canClickLocationDropdown && !isOnMembership,
            [styles.noWrap]: isMarketplacePlatform,
          })}
          data-testid="HeaderAddress"
          onClick={handleAddressClick}
          aria-expanded={!!showLocationDropdown}
          type="button"
        >
          {canClickLocationDropdown && (
            <div
              className={classNames(styles.caretWrapper, {
                [styles.rotate]: showLocationDropdown,
              })}
            >
              <IconCaretDown alt="show location dropdown" />
            </div>
          )}
          {(!isMarketplacePlatform || !isMobile) && (
            <div className={styles.address}>{formattedAddress}</div>
          )}
          {showLocationDropdown && (
            <LocationsMenu
              restaurantId={restaurantId}
              locations={locations}
              handleLocationSwitch={handleLocationSwitch}
              closeLocationDropdown={() => setShowLocationDropdown(false)}
            />
          )}
        </button>
      )}
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div
        className={classNames(styles.dropdownOverlay, {
          [styles.visible]: showLocationDropdown,
        })}
        onClick={() => setShowLocationDropdown(false)}
      />
    </>
  );
}

export default RestaurantName;
